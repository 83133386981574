import pink_logo_large from '../Images/Logos/pink_logo_large.png';
import '../App.css';

export default function Home() {
    return <div>
        <header className="App-header">
          <p className="Welcome-text Heading-text-color">Welcome to Circle City Climbing!</p>
          <img src={pink_logo_large} className="App-logo" alt="logo" />
        </header>
        <p className="About-text Info-text-color">We are Indiana's premier independent youth competitive rock climbing team.</p>
        <p className="Contact-text Info-text-color">If you or your youth climber are interested in joining, please contact coach Jessi at</p>
        <p className="Bold-text Info-text-color">circlecityclimbing@gmail.com</p>
    </div>
}