import AnchorLink from "react-anchor-link-smooth-scroll";
import fidget_logo from "../Images/Logos/fidget_logo.png";

export default function About() {
    return <div>
        <div className="About-page">
            <ul className="Heading-text-color">
                <li>
                    <AnchorLink href="#Overview" className="Anchor-link">
                        Overview
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink href="#Competitive Climbing" className="Anchor-link">
                        Competitive Climbing
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink href="#Outdoor Climbing" className="Anchor-link">
                        Outdoor Climbing
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink href="#Community Service" className="Anchor-link">
                        Community Service
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink href="#Independence and Education" className="Anchor-link">
                        Independence and Education
                    </AnchorLink>
                </li>
            </ul>
            <img src={fidget_logo} alt="Circle City Climbing Logo" />
            <h1 id="Overview" className="Heading-text-color">Overview</h1>
            <p className="Info-text-color">&emsp;&emsp;Circle City Climbing Team is Indiana's premier competitive youth climbing team, led by Head Coach Jessi Haynes, Associate Head Coach Jake Krebs, Assistant Coaches Ryan Gadberry and Max Elliott and Assistant Speed Coach Evan Homan. Our primary goals for our athletes are health, well-being, independence, education, a service-minded commitment to their sport and community, and character development through competitive sport. We keep character and inclusivity at the forefront of all we do.</p>
            <h1 id="Competitive Climbing" className="Heading-text-color">Competitive Climbing</h1>
            <p className="Info-text-color">&emsp;&emsp;The primary focus of our program is preparing our athletes for high-level competitive rock climbing. Our program is open to ages 8-19 and includes training in all three primary competitive climbing disciplines - sport, speed and bouldering. Our coaches have produced National and International Champions, professional climbers, climbing coaches, triathletes and fitness professionals (and an Olympian!) through our continued commitment to the development of the athlete as a whole person.</p>
            <h1 id="Outdoor Climbing" className="Heading-text-color">Outdoor Climbing</h1>
            <p className="Info-text-color">&emsp;&emsp;Our program seeks to educate our climbers on ethical and safe outdoor climbing practices through consistent opportunities for outdoor climbing, primarily in the Red River Gorge, New River Gorge, Mad River Gorge, Stone Fort and Holy Boulders. Our hope is that by providing a rich depth of opportunities for engagement with our sport, we are able to develop lifelong climbers who are committed to protecting outdoor climbing areas and maintaining access.</p>
            <h1 id="Community Service" className="Heading-text-color">Community Service</h1>
            <p className="Info-text-color">&emsp;&emsp;In order to maintain a position with Circle City Climbing, climbers must have a service-minded attitude toward their teammates, climbing community, and broader community. Through service projects such as Trail Days with the Red River Gorge Climbers' Coalition and volunteering with our local food banks, we hope to instill in our climbers a reverence for helping others and a desire to have a lasting, positive impact on their community.</p>
            <h1 id="Independence and Education" className="Heading-text-color">Independence and Education</h1>
            <p className="Info-text-color">&emsp;&emsp;Our practices are developed on a foundation of education and independence that, over time, allows our climbers to learn to train safely and independently as they age. By keeping our coach-to-climber ratios low and providing monthly 1-on-1 coaching for each climber, our coaching staff is able to focus attention on each individual climber's needs and goals, helping them to understand how to train safely even while not under coach supervision.</p>
        </div>
    </div>
}