import { Link } from "react-router-dom";

export default function Navbar() {
    return <nav className="Nav">
        <Link className="Site-title" to="/">Circle City Climbing</Link>
        <ul>
            <li>
                <Link to="/about" className="Page-title">About</Link>
            </li>
            <li>
                <Link to="/gallery" className="Page-title">Gallery</Link>
            </li>
        </ul>
    </nav>
}