import ImageGallery from 'react-image-gallery';
import Gallery_1 from '../Images/Photos/Gallery_1.JPG';
import Gallery_2 from '../Images/Photos/Gallery_2.jpg';
import Gallery_3 from '../Images/Photos/Gallery_3.jpg';
import Gallery_4 from '../Images/Photos/Gallery_4.jpg';
import Gallery_5 from '../Images/Photos/Gallery_5.jpg';
import Gallery_6 from '../Images/Photos/Gallery_6.JPG';
import Gallery_7 from '../Images/Photos/Gallery_7.jpg';
import Gallery_8 from '../Images/Photos/Gallery_8.jpg';
import Gallery_9 from '../Images/Photos/Gallery_9.jpg';
import Gallery_10 from '../Images/Photos/Gallery_10.jpg';
import Gallery_11 from '../Images/Photos/Gallery_11.jpg';
import Gallery_12 from '../Images/Photos/Gallery_12.jpg';
import Gallery_13 from '../Images/Photos/Gallery_13.jpg';


const images = [
    {
        original: Gallery_1,
        thumbnail: Gallery_1
    },
    {
        original: Gallery_2,
        thumbnail: Gallery_2
    },
    {
        original: Gallery_3,
        thumbnail: Gallery_3
    },
    {
        original: Gallery_4,
        thumbnail: Gallery_4
    },
    {
        original: Gallery_5,
        thumbnail: Gallery_5
    },
    {
        original: Gallery_6,
        thumbnail: Gallery_6
    },
    {
        original: Gallery_7,
        thumbnail: Gallery_7
    },
    {
        original: Gallery_8,
        thumbnail: Gallery_8
    },
    {
        original: Gallery_9,
        thumbnail: Gallery_9
    },
    {
        original: Gallery_10,
        thumbnail: Gallery_10
    },
    {
        original: Gallery_11,
        thumbnail: Gallery_11
    },
    {
        original: Gallery_12,
        thumbnail: Gallery_12
    },
    {
        original: Gallery_13,
        thumbnail: Gallery_13
    }
]

export default function Gallery() {
    return <div className="Slideshow">
        <ImageGallery items={images} />
    </div>
}